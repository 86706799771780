<template>
  <div class="page rooms-page">
    <sections-elem :content="$store.state.page.content.sections"></sections-elem>
    <div
      v-for="(box, i) in $store.state.page.content.rooms"
      :key="i"
      class="section row text">
        <boxes-elem
          :boxes="box"
          class="col is-10 is-12-sm is-offset-1 is-no-offset-sm room">
        </boxes-elem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rooms-page'
}
</script>

<style lang="sass">
.rooms-page
  .sections-elem
    margin-bottom: m(4)
  .room
    >.row
      .box
        >.row
          flex-direction: row-reverse
          .image
            //margin-top: m(7)
    &.has-1
      .image
        flex-basis: 50%
      .text
        flex-basis: 50%
</style>